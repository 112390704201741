.button {
  background-color: #EDF6FA;
  border-radius: 4px;
  height: 64px;
  display: flex;
  align-items: center;
  padding: 20px;
  color: #272824;
  font-size: 16px;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background-color: rgba(#62CAFE, 0.5);
  }
}

.active {
  background-color: #62CAFE;
  color: #FFFFFF;
  cursor: default;

  &:hover {
    background-color: #62CAFE;
  }

  & > .chevron {
    stroke: #FFFFFF;
  }
}

.chevron {
  margin-right: 6px;
}