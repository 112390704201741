.button {
  height: 64px;
  border-radius: 4px;
  background: #EDF6FA;
  display: flex;
  gap: 16px;
  align-items: center;
  border: none;
  padding: 0 24px;
  color: #282828;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.2s;
  flex-shrink: 0;

  &:hover {
    filter: brightness(95%);
  }
}

.active {
  color: #FFFFFF;
  background-color: #62CAFE;
}