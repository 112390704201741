.button {
  position: relative;
  width: 163px;
  height: 44px;
  border-radius: 4px;
  background: #282828;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1.4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.input {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;

  &::-webkit-file-upload-button {
    visibility: hidden;
  }
}
