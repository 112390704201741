.chat {
  display: flex;
  flex-direction: column;
  height: calc(100% - 68px);
  gap: 8px;
  overflow: auto;
}

.window {
  flex-grow: 1;
  border: 2px solid rgba(#242728, 5%);
  border-radius: 4px;
  overflow-y: scroll;
}

.inputWrapper {
  position: relative;
}

.input {
  display: block;
  height: 128px;
  width: 100%;
  border-radius: 4px;
  padding: 32px 48px;
  font-size: 16px;
  color: #242728;
  resize: none;
  border: 2px solid #242728;

  &::placeholder {
    font-weight: 500;
    opacity: 0.5;
  }

  &:disabled {
    opacity: 0.5;
    background-color: #E6EEF2;
  }
}

.send {
  position: absolute;
  right: 8px;
  bottom: 8px;
  cursor: pointer;
}