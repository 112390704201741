.sources {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 8px;
  user-select: None;
}

.circle {
  border-radius: 50%;
  width: 13px;
  height: 13px;
  background: #62CAFE;
  color: #fff;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circleSelected {
  color: #5C5C5C;
  background-color: #fff;
}


.buttonText {
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 500;
  max-width: 80px;
  mask-image: linear-gradient(90deg, #D0DFE7 40%, rgba(208, 223, 231, 0) 100%);
  text-overflow: fade;
  white-space: nowrap;
  color: #282828;
  font-size: 14px;
  line-height: normal;
}

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  cursor: pointer;
  isolation: isolate;
  width: 132px;
  height: 37px;
  background: #D0DFE7;
  border-radius: 4px;

  &:hover {
    filter: brightness(95%);
  }
}

.buttonSelected {
  background: #62CAFE;
}


.source {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  width: 580px;
  background: #FAFDFF;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.header {
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #282828;
  opacity: 0.5;
  width: 100%;
  padding: 12px 16px 4px 16px;
}

.body {
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  padding: 0 16px 12px 16px;
  color: rgba(40, 40, 40, 0.75);
}

.footer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: baseline;
  padding: 8px 16px 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.03);
  width: 100%;
}

.footerName {
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #5C5C5C;
}

.footerText {
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #5C5C5C;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}