.background {
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: rgba(40, 40, 40, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog {
  width: 416px;
  border-radius: 8px;
  background: #FFFFFF;
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.10), 0 24px 80px 0 rgba(0, 0, 0, 0.10), 0 4px 4px 0 rgba(0, 0, 0, 0.05);
}

.header {
  border-radius: 8px 8px 0 0;
  padding: 21px 32px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: rgba(#282828, 0.5);
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid #D0DFE7;
  background-color: #EDF6FA;
}

.main {
  padding: 24px 32px;
}

.title {
  color: #282828;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
}

.footer {
  display: flex;
  padding: 0 32px 32px;
  gap: 8px;
}

.cancel {
  height: 43px;
  width: 124px;
  border: none;
  border-radius: 4px;
  background-color: #D0DFE7;
  color: #282828;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1.4px;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    filter: brightness(95%);
  }
}

.submit {
  height: 43px;
  border-radius: 4px;
  background: rgba(#282828, 0.25);
  flex-grow: 1;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1.4px;
  gap: 8px;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background: rgba(#282828, 0.4);
  }
}

.disabled {
  opacity: 0.5;
  cursor: initial;

  &:hover {
    background: rgba(#282828, 0.25);
  }
}