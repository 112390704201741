.main {
  background-color: #FFFFFF;
  border-radius: 8px;
  padding: 8px;
  flex-grow: 1;
}

.inner {
  display: flex;
  flex-direction: column;
  border: 1px solid #F4F4F4;
  border-radius: 8px;
  height: 100%;
  padding: 40px 32px;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.headerSection {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  color: #282828;
}

.icon {
  margin-right: 16px;
}

.search {
  position: relative;
}

.input {
  width: 320px;
  height: 44px;
  padding: 14px 24px 14px 48px;
  border: 2px solid rgba(#458EB280, 0.5);
  border-radius: 4px;
}

.searchIcon {
  display: block;
  position: absolute;
  top: 14px;
  left: 24px;
  pointer-events: none;
}

.body {
  display: flex;
  gap: 24px;
  min-height: 0;
  flex-shrink: 1;
  align-items: flex-start;
}

.left {
  width: 290px;
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.newCollection {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1.4px;
  width: 100%;
  gap: 8px;
  flex-shrink: 0;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  background-color: #D0DFE7;
  border-radius: 4px;
  padding: 0 24px;
  cursor: pointer;
  border: none;
  &:hover {
    filter: brightness(95%);
  }
}

.collections {
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: scroll;
}

.right {
  flex-grow: 1;
  height: 100%;
}