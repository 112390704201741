.message {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 32px 0;

  &:first-child {
    border-radius: 4px 4px 0 0;
  }

  &:last-child {
    border-radius: 0 0 4px 4px;
  }
}

.bot {
  background-color: #F0F3F5;
}

.icon {
  margin-right: 24px;
}
.UserIcon{
  width: 45px;
  margin-right: 24px;
  border-radius: 100px;
}
.text {
  width: 580px;
  font-size: 16px;
  line-height: 24px;
  color: #242728;
}

.sourcesContainer {
  margin-top: 16px;
  display: flex;
  line-height: 26px;
  font-weight: 500;
}

.sources {
  display: flex;
  margin-left: 8px;
  gap: 8px;
}

.source {
  background: #62CAFE;
  border-radius: 4px;
  color: #FFFFFF;
  height: 26px;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px 0;
  gap: 8px;
  cursor: pointer;

  &:hover {
    filter: brightness(95%);
  }
}
