.notification {
  position: fixed;
  width: 384px;
  max-width: calc(100vw - 48px);
  margin-bottom: 16px;
  margin-inline-start: auto;
  padding: 20px 24px;
  overflow: hidden;
  right: 20px;
  top: 0px;
  z-index: 999;
  line-height: 1.5714285714285714;
  word-wrap: break-word;
  background: #ffffff;
  border-radius: 8px;
   transform: translateY(100%);
   opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.close-button {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: rgba(40, 40, 40, 0.75);
  top: 10px;
}

.notification-content {
  display: flex;
  align-items: center;
}

.notification-content img {
  width: 20px;
  margin-right: 5px;
}

.NotificationHidden {
  /* display: none;  */
  opacity: 0;
  transform: translateY(-50px);
}

.NotificationVisible {
 /* display: block; */
  opacity: 1;
  transform: translateY(20px);
}
