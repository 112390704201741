.dropdown {
  position: relative;
}

.dropdownMenu {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  width: 250px;
  background-color: #FFFFFF;
  border-radius: 4px;
  z-index: 1;
  list-style: none;
  padding: 0;
  margin: 0;
  box-shadow:
          0 8px 24px 0 rgba(0, 0, 0, 0.10),
          0 24px 80px 0 rgba(0, 0, 0, 0.10),
          0 4px 4px 0 rgba(0, 0, 0, 0.05);
}

.option {
  height: 43px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #282828;
  font-size: 14px;
  font-weight: 500;
  padding: 0 24px;

  &:hover {
    background-color: #f1f1f1;
  }
}

.name {
  display: flex;
  align-items: center;
  gap: 8px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  border-radius: 4px;
  padding: 0 24px;
  cursor: pointer;
  border: none;
  flex-shrink: 0;
  transition: 0.2s;
  gap: 8px;
  color: #282828;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1.4px;

  &:hover {
    filter: brightness(95%);
  }
}

.active {
  background-color: #62CAFE;
  color: #FFFFFF;
}