.box {
  border-radius: 4px;
  border: 2px solid #F4F4F4;
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.buttons {
  display: flex;
  gap: 8px;
  padding: 10px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  border-radius: 4px;
  padding: 0 24px;
  cursor: pointer;
  border: none;
  flex-shrink: 0;
  transition: 0.2s;
  background-color: #D0DFE7;
  color: #282828;
  gap: 8px;

  &:hover {
    filter: brightness(95%);
  }
}

.header {
  display: grid;
  align-items: center;
  grid-template-columns: 10% 40% 20% 20% 10%;
  background: #EDF6FA;
  height: 48px;
  flex-shrink: 0;
}

.checkbox {
  width: 20px;
  height: 20px;
  border: 2px solid #F4F4F4;
  margin-left: 24px;
}

.list {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  overflow: scroll;
}

.text {
  color: #282828;
  font-size: 14px;
  font-weight: 500;
  opacity: 0.5;
}

.items {
  flex-shrink: 1;
  overflow-y: scroll;
  display: grid;
  background-color: #F4F4F4;
  grid-gap: 1px;
}

