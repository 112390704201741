.nav {
  background-color: #CAD9E0;
  padding: 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.line {
  height: 62px;
  width: 1px;
  background-color: #282828;
  opacity: 0.1;
}

.top {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.button {
  height: 64px;
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #D0DFE7;
  border: none;
  cursor: pointer;

  &:hover {
    filter: brightness(95%);
  }
}

.active {
  background-color: #62CAFE;
}