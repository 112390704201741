.row {
  display: grid;
  grid-template-columns: 10% 40% 20% 20% 10%;
  height: 64px;
  align-items: center;
  background-color: #FFFFFF;
}

.checkbox {
  width: 20px;
  height: 20px;
  border: 2px solid #F4F4F4;
  margin-left: 24px;
}

.name {
  display: flex;
  align-items: center;
  gap: 16px;
}

.icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #EDF6FA;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  color: #282828;
  font-size: 14px;
  font-weight: 500;
}

.actions {
  display: flex;
  gap: 16px;
}

.delete svg {
  cursor: pointer;
  display: flex;
  transition: 0.2s;

  &:hover path {
    stroke: #8A8F91;
  }
}