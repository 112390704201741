.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #282828;
  font-size: 16px;
  padding: 10px 10px 10px 24px;
  font-weight: 500;
}

.outline {
  outline: 2px solid #282828;
  z-index: 1;
}

.backgroundGrey {
  background-color: #458EB280;
  height: 66px;
  border-radius: 4px 4px 0 0;
  width: calc(100% + 4px);
  margin-left: -2px;
  margin-top: -2px;
}

.input {
  flex-grow: 1;
  border: none;
  height: 100%;
  color: #282828;
  font-size: 16px;
  font-weight: 500;

  &:focus {
    outline: none;
  }
}

.buttons {
  display: flex;
  gap: 8px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  border-radius: 4px;
  padding: 0 24px;
  cursor: pointer;
  border: none;
  flex-shrink: 0;
  transition: 0.2s;
  gap: 8px;
  color: #282828;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1.4px;

  &:hover {
    filter: brightness(95%);
  }
}

.grey {
  background-color: #D0DFE7;
  color: #282828;
}

.black {
  background-color: #282828;
  color: #FFFFFF;
}

.red {
  background-color: #FE6262;
  color: #FFFFFF;
}

.disabled {
  opacity: 0.5;
}

.flex {
  display: flex;
  gap: 8px;
}

// .input {
//   position: absolute;
//   height: 100%;
//   width: 100%;
//   left: 0;
//   top: 0;
//   opacity: 0;
//   cursor: pointer;

//   &::-webkit-file-upload-button {
//     visibility: hidden;
//   }
// }