.historyContainer {
  background-color: #E6EEF2;
  border-radius: 8px;
  width: 344px;
  min-width: 344px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
}

.logs {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-grow: 0;
}

.newChatButton {
  border-radius: 4px;
  background-color: #282828;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: bold;
  min-height: 64px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s;
  border: none;

  &:hover {
    opacity: 0.8;
  }
}

.tooltip {
  color: #282828;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: pre-wrap;
  gap: 16px;
  opacity: 0.5;
  margin-top: 24px;
}


.box {
  background-color: #FFFFFF;
  border-radius: 8px;
  flex-grow: 1;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.header {
  border-radius: 4px;
  border: 2px solid rgba(36, 39, 40, 0.05);
}

.none {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #282828;
  opacity: 0.5;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  white-space: pre-wrap;
  gap: 12px;
  width: 100%;
}

.container {
  display: flex; 
  flex-direction: column; 
  justify-content: space-between;
  align-items: stretch; 
  height: 100vh; 
}

.left, .right, .bottom {
  padding: 10px; 
}

.left {
  background-color: #f2f2f2;
}

.right {
  background-color: #e0e0e0;
}

.bottom {
  background-color: #ccc; 
}

.homeLayout{
    display: flex;
    flex: auto;
    flex-direction: column;
    min-height: 0;
    .layoutHasSider{
      flex-direction: row;
      display: flex;
      flex: auto;
      min-height: 0;
      .layoutSider{
        color: rgb(255, 255, 255);
        // flex: 0 0 230px;
        padding: 0 2px;
        // width: 300px;
        .layoutSiderCont{
          border: 2px solid  rgba(36, 39, 40, 0.05);
          height: 100%;
          border-radius:4px ;
          color: #282828;
          font-size: 16px;
          font-weight: 500;
          // padding: 15px;
          position: relative;
          .layoutSiderButton{
            position: absolute;
            right: -14px;
            top: 21px;
            background-color: rgba(0, 0, 0, 0.5);
            color: white;
            border: none;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 15px;
            cursor: pointer;
            transition: background-color 0.3s;
          }
          .layoutSiderButton:hover{
            background-color: rgba(0, 0, 0, 0.8);
          }
        }
      }
      .layoutContent{
        width: 100%;
      }
    }
}