.collections {
  border-radius: 4px;
  border: 2px solid #D0DFE7;
  max-height: 300px;
  overflow-y: scroll;
}

.collection {
  display: flex;
  align-items: center;
  padding-left: 24px;
  gap: 8px;
  height: 53px;
  color: #282828;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background-color: #62CAFE;
  }
}

.none {
  padding: 30px;
  text-align: center;
  color: #282828;
  font-size: 14px;
  font-weight: 500;
}

.collectionGrey {
  background: #EDF6FA;
}

.active {
  background-color: #62CAFE;
  color: #FFFFFF;
}